<template>
    <div class="bg-blue-50 px-6">
        <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
            <div class="px-12 py-6">
                <h1 
                class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
                <faIcon
                icon="user-edit"
                type="fas"
                class="text-green-500 text-3xl"
                >
                </faIcon>
                Editar usuario
                </h1>
                <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Modifica los datos del usuario seleccionado.</p>
            </div>
        </div>
        <div class="flex-col justify-center items-center bg-gray-200 mt-4">
        
            <div class="p-6 bg-white rounded-md shadow-md">
                <RegisterForm class="mt-4" :userId="parseInt($route.params.id)"/>
            </div>
        </div>    
    </div>
</template>
<script>
import RegisterForm from "@/components/usuarios/RegisterUserForm"

export default {
    name: "UserEditView",
    components: {
        RegisterForm
    }
}
</script>